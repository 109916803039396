import defaultTheme from './default'

let provenanceTheme = { ...defaultTheme }

// Provenance official colours
const provenanceDarkAccent_1 = '#070a2d' // Purple
const provenanceLightAccent_1 = '#af883b' // Gold

// Provenance styling extras

const darkTextColour = provenanceDarkAccent_1
const iconColour =  provenanceLightAccent_1

const primaryColour = darkTextColour 
const secondaryColour = darkTextColour
const gradiantStart = '#FFFFFF'
const gradientEnd = '#FFFFFF'
const headerBackgroundColour = '#FFFFFF'
const labelColour = darkTextColour
const darkAccent = provenanceDarkAccent_1
const fontFamily = 'Futura-PT'

provenanceTheme.labelTextColour = labelColour
provenanceTheme.backgroundColour = 'white'
provenanceTheme.primaryColour = primaryColour
provenanceTheme.primaryTextColour = '#333'
provenanceTheme.headerFontColour = primaryColour
provenanceTheme.buttonAccentColour = darkAccent
provenanceTheme.buttonBackgroundColour = darkAccent
provenanceTheme.infoBackgroundColour ='#244c5a'
provenanceTheme.inputBorderColour = primaryColour
provenanceTheme.inputActiveBorderColour = provenanceLightAccent_1
provenanceTheme.infoIconColour = primaryColour

// Fonts
provenanceTheme.fontFamily = fontFamily
provenanceTheme.secondaryFontFamily = fontFamily
provenanceTheme.headerFontFamily = fontFamily
provenanceTheme.linkColour = provenanceLightAccent_1
provenanceTheme.inputFontFamily = fontFamily
provenanceTheme.LegalDecsfontFamily = fontFamily
provenanceTheme.labelFontFamily = fontFamily

provenanceTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: darkAccent,
  activeBorderColor: darkAccent,
  defaultColor: provenanceLightAccent_1,
  defaultBorderColor: provenanceLightAccent_1,
  defaultBarColor: provenanceLightAccent_1,
  completeColor: darkAccent,
  completeBorderColor: darkAccent,
  completeBarColor: darkAccent,
}
provenanceTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
provenanceTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour,
  durationButtonForeColour: provenanceLightAccent_1
}
provenanceTheme.button = {
  ...defaultTheme.button,
  fontFamily: fontFamily,
  backgroundColour: secondaryColour,
  addon: {
    backgroundColour: secondaryColour,
    disabledFont: '#fff',
    disabledBackground: secondaryColour,
  },
}
provenanceTheme.header = {
  ...defaultTheme.header,
  background: headerBackgroundColour,
  borderBottom: 'none',
}
provenanceTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  textColour: darkTextColour,
  padding: '0px',
  hover: {
    textDecoration: 'underline',
    color: '#fff',
    backgroundColour: primaryColour,
    padding: '0px',
  },
}

provenanceTheme.gradient = {
  ...defaultTheme.gradient,

  background: gradiantStart,
  startColour: gradientEnd,
}
provenanceTheme.icon = {
  ...defaultTheme.icon,
  color: iconColour,
  backgroundColor: darkAccent,
}
provenanceTheme.GbIcon = iconColour
provenanceTheme.editIconColour = iconColour,
provenanceTheme.formControlValidIcon = {
  ...defaultTheme.formControlValidIcon,
  colour: '#fff',
  backgroundColour: primaryColour,
}
provenanceTheme.headerFontColour = primaryColour
provenanceTheme.inputBorderColour = darkAccent
provenanceTheme.successColour = primaryColour
provenanceTheme.marketingPreferencesColour = secondaryColour
provenanceTheme.svg = {
  ...defaultTheme.svg,
  primaryColour: primaryColour,
  accentColour: '#FFBABF',
}
provenanceTheme.icons = {
  border: '',
  height: '33px',
  width: '43px',
}


export default provenanceTheme
