import React, { useEffect, useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Grid } from 'unsemantic'
import {
  useAffiliate,
  useConfig,
  usePrice,
  useQueryString,
  useQuote,
  useDataLayer,
  useAddons,
} from 'hooks'
import GridContainerWrapper from 'components/@common/GridContainerWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { updateStep } from 'containers/App/actions'
import {
  priceCheckPaymentPage,
  priceMatch,
} from 'containers/PaymentPage/actions'
import { history } from 'index'
import Card from 'containers/Stripe/Card'
import { InitialStateType } from 'initialState'
import useCustomerAccountFeatureFlag from 'hooks/useCustomerAccountFeatureFlag'
import useOptimizelyExperiment from 'hooks/experiments/useOptimizelyExperiment'
import { ReactSDKClient, withOptimizely } from '@optimizely/react-sdk'
import { PaymentContent } from './components'
import { DiscountVoucher } from './components/DiscountVoucher'
import { StripePaymentWrapper } from './Payment.styles'
import NewPayment from './NewPayment'
import config from 'config'
import useStripePaymentServiceExperiment from 'hooks/experiments/PaymentServiceExperimentConfig'

interface PaymentProps {
  optimizely: ReactSDKClient | null
}

const stripePromise = loadStripe(config.STRIPE_KEY)

const Payment: React.FC<PaymentProps> = ({ optimizely }) => {
  const shouldUseSplitAddonsPage = useOptimizelyExperiment(
    optimizely,
    'addons_page',
    'page_enabled',
  )
  const config = useConfig()
  const { isPriceComparison, affiliate } = useAffiliate()
  const { price } = usePrice()
  const { availableAddons } = useAddons()
  const { quote } = useQuote()
  const { queryString } = useQueryString()
  const dispatch = useDispatch()

  const isPaymentVariant = useStripePaymentServiceExperiment()

  const {
    data: useStripePaymentElementsOnAffiliatesFlagEnabled,
    isLoading: useStripePaymentElementsOnAffiliatesFlagLoaded,
  } = useCustomerAccountFeatureFlag('UseStripePaymentElements-Affiliates')

  const {
    data: useStripePaymentElementsFlagEnabled,
    isLoading: useStripePaymentElementsFlagLoaded,
  } = useCustomerAccountFeatureFlag('UseStripePaymentElements')

  const shouldAffiliatesUseStripePaymentElements = useMemo(
    () =>
      !useStripePaymentElementsOnAffiliatesFlagLoaded &&
      useStripePaymentElementsOnAffiliatesFlagEnabled?.data,
    [
      useStripePaymentElementsOnAffiliatesFlagEnabled?.data,
      useStripePaymentElementsOnAffiliatesFlagLoaded,
    ],
  )

  const shouldDirectUseStripePaymentElements = useMemo(
    () =>
      !useStripePaymentElementsFlagLoaded &&
      useStripePaymentElementsFlagEnabled?.data,
    [
      useStripePaymentElementsFlagEnabled?.data,
      useStripePaymentElementsFlagLoaded,
    ],
  )

  const multiPriceVariant = useSelector(
    (state: InitialStateType) =>
      state.experiments[config.AB_TESTS.PRICE_COMPARISON_EXPERIMENT_ID],
  )

  const { data: featureFlagEnabled, isLoading: featureFlagLoaded } =
    useCustomerAccountFeatureFlag('MentionMePocEnabled')

  const { pushQuoteReferenceNumber } = useDataLayer()

  const updateStepNo =
    shouldUseSplitAddonsPage && availableAddons && availableAddons.length
      ? 4
      : 3

  useEffect(() => {
    dispatch(updateStep(updateStepNo))
    pushQuoteReferenceNumber()
  }, [dispatch, pushQuoteReferenceNumber, updateStepNo])

  useEffect(() => {
    if (!isPriceComparison && multiPriceVariant === 0) {
      if (
        quote.Underwriter?.toLowerCase() !== price.Underwriter?.toLowerCase()
      ) {
        // show message on driving licence page & update premium units
        dispatch(priceCheckPaymentPage())
        history.push({
          pathname: '/quote/driving-licence',
          search: `${queryString}`,
        })
      } else if (quote.TotalPrice !== price.TotalPrice) {
        // show message on payment page & update premium units
        dispatch(priceMatch(false))
        dispatch(priceCheckPaymentPage())
      }
    }

    if (!featureFlagLoaded && featureFlagEnabled && featureFlagEnabled?.data) {
      const script = document.createElement('script')

      const body = document.getElementsByTagName('body')[0]

      const scriptPath = `${config.MENTION_ME_URL}/api/v2/refereefind/${config.MENTION_ME_ID}?situation=checkout&locale=en_GB`

      script.src = scriptPath

      body.appendChild(script)
    }
  }, [
    isPriceComparison,
    quote,
    price,
    multiPriceVariant,
    dispatch,
    queryString,
    config.MENTION_ME_URL,
    config.MENTION_ME_ID,
    featureFlagLoaded,
    featureFlagEnabled,
  ])

  const url = new URL(window.location.href)
  const paymentElementsEnabled = url.searchParams.get('payment_elements')

  if (affiliate?.Ref && shouldAffiliatesUseStripePaymentElements) {
    return <NewPayment />
  }

  if (
    !affiliate?.Ref &&
    (Boolean(paymentElementsEnabled) || isPaymentVariant)
  ) {
    return <NewPayment />
  }

  return (
    <GridContainerWrapper>
      <>
        <PaymentContent />
        <div id='mmWrapper' />

        {!featureFlagLoaded &&
          featureFlagEnabled &&
          featureFlagEnabled?.data && <DiscountVoucher />}

        <Grid desktop='100' mobile='100' tablet='100'>
          <StripePaymentWrapper>
            <Elements stripe={stripePromise}>
              <Card />
            </Elements>
          </StripePaymentWrapper>
        </Grid>
      </>
    </GridContainerWrapper>
  )
}

export default withOptimizely(Payment)
