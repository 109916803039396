import { useEffect, useState } from 'react'
import useScheme from 'hooks/useScheme'
import config from 'config'
import { getExperimentBucket } from './optimizely-utils'

const STRIPE_PAYMENT_SERVICE_ORIGINAL = '5479306147659776'
const STRIPE_PAYMENT_SERVICE_VARIANT = '4625310486888448'
const STRIPE_PAYMENT_SERVICE_EXPERIMENT_ID = 5838608188309504

const useStripePaymentServiceExperiment = () => {
  const [useVariation, setUseVariation] = useState(false)
  const { scheme } = useScheme()

  useEffect(() => {
    const setupExperiment = () => {

      const bucket = getExperimentBucket(
        STRIPE_PAYMENT_SERVICE_EXPERIMENT_ID,
      ) || {
        id: STRIPE_PAYMENT_SERVICE_ORIGINAL,
      }

      const shouldUseVariation = bucket.id === STRIPE_PAYMENT_SERVICE_VARIANT
      setUseVariation(shouldUseVariation)

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'experiment.loaded',
          experimentId: STRIPE_PAYMENT_SERVICE_EXPERIMENT_ID,
          variationId: bucket.id,
        })
      }
    }

    const onOptimizelyTagLoaded = () => {
      setupExperiment()
    }

    if (config.ENVIRONMENT_NAME !== 'Production') {
      setupExperiment()
      return
    }

    const timeout = setTimeout(setupExperiment, 2000)
    document.addEventListener('optimizely-tag-loaded', onOptimizelyTagLoaded)

    return () => {
      clearTimeout(timeout)
      document.removeEventListener(
        'optimizely-tag-loaded',
        onOptimizelyTagLoaded,
      )
    }
  }, [scheme])

  return useVariation
}

export default useStripePaymentServiceExperiment
