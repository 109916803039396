import QueryString from 'query-string'
import compareTheMarketTheme from 'themes/compareTheMarketTheme'
import uswitchTheme from 'themes/uswitch'
import aPlanTheme from '../themes/aplan'
import arnoldClarkTheme from '../themes/arnoldClarkTheme'
import brightsideTheme from '../themes/brightside'
import defaultTheme from '../themes/default'
import motorEasyTheme from '../themes/motorEasyTheme'
import oneAnswerTheme from '../themes/oneAnswerTheme'
import quidcoTheme from '../themes/quidcoTheme'
import rightChoiceTheme from '../themes/rightChoiceTheme'
import strideTheme from '../themes/strideTheme'
import {
  APLAN_REF,
  ARNOLD_CLARK_REF,
  BRIGHTSIDE_REF,
  CTM_REF,
  MOTOR_EASY_REF,
  NON_BRANDED,
  ONE_ANSWER_REF,
  PROVENANCE_REF,
  QUIDCO_REF,
  RIGHT_CHOICE_REF,
  SEMI_BRANDED,
  STRIDE_REF,
  USWITCH_REF,
  WHITE_LABEL,
} from './affiliateConstants'
import provenanceTheme from 'themes/provenance'

class themeService {
  static getTheme(affiliate) {
    const parsedQuerystring = QueryString.parse(window.location.search)
    switch (affiliate.AffiliateType) {
      case WHITE_LABEL:
        switch (affiliate.Ref) {
          case APLAN_REF:
            return aPlanTheme
          case BRIGHTSIDE_REF:
            return brightsideTheme
          case ONE_ANSWER_REF:
            return oneAnswerTheme
          case RIGHT_CHOICE_REF:
            return rightChoiceTheme
          case STRIDE_REF:
            return strideTheme
          case ARNOLD_CLARK_REF:
            return arnoldClarkTheme
          case MOTOR_EASY_REF:
            return motorEasyTheme
          case QUIDCO_REF:
            return quidcoTheme
          case CTM_REF:
            return compareTheMarketTheme
          case USWITCH_REF:
            return uswitchTheme
          case PROVENANCE_REF:
             return provenanceTheme
          default:
            return defaultTheme
        }
      case SEMI_BRANDED:
      case NON_BRANDED:
      default:
        switch (parsedQuerystring.ref) {
          case APLAN_REF:
            return aPlanTheme
          case BRIGHTSIDE_REF:
            return brightsideTheme
          case ONE_ANSWER_REF:
            return oneAnswerTheme
          case RIGHT_CHOICE_REF:
            return rightChoiceTheme
          case STRIDE_REF:
            return strideTheme
          case ARNOLD_CLARK_REF:
            return arnoldClarkTheme
          case MOTOR_EASY_REF:
            return motorEasyTheme
          case QUIDCO_REF:
            return quidcoTheme
          default:
            return defaultTheme
        }
    }
  }
}

export default themeService
